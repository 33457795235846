import box from "./box"
import text from "./text"
import links from "./links"

// value         |0px     576px    768px    992px   1200px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl
const breakpoints = ["36em", "48em", "62em", "75em"]

const fonts = {
  body:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
  heading:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
}

const colors = {
  background: "#151818",
  black: "#151818",
  white: "#fff",
  grey: "#151818",
  lightgrey: "#c6c6c6",
  red: "#ce0000",
}

//                [ 0,  1,  2,  3,  4,  5,  6,  7,  8,  9, 10];
const fontSizes = [14, 16, 18, 20, 22, 28, 30, 32, 35, 45, 60, 100]
const fontWeights = {
  body: 400,
  heading: 700,
  semibold: 500,
  bold: 700,
  thin: 200,
}
const lineHeights = {
  body: 1.47059,
  heading: 1.25,
}
const radii = {
  default: 0,
}

const space = [
  0, // 0: 0px
  "0.25rem", // 1: 4px
  "0.5rem", // 2: 8px
  "1.0rem", // 3: 16px
  "1.5rem", // 4: 24px
  "2.0rem", // 5: 32px
  "2.5rem", // 6: 40px
  "3.0rem", // 7: 48px
  "3.5rem", // 8: 56px
  "4.0rem", // 9: 64px
  "4.5rem", // 10: 72px
  "5.0rem", // 11: 80px
  "5.5rem", // 12: 88px
  "6.0rem", // 13: 96px
  "6.5rem", // 14: 104px
  "7.0rem", // 15: 112px
  "7.5rem", // 16: 120px
  "8.0rem", // 17: 128px
  "8.5rem", // 18: 136px
  "9.0rem", // 19: 144px
  "9.5rem", // 20: 152px
  "10.0rem", // 21: 160px
  "10.5rem", // 22: 168px
  "11.0rem", // 23: 176px
  "11.5rem", // 24: 184px
  "12.0rem", // 25: 192px
  "12.5rem", // 26: 200px
  "13.0rem", // 17: 208px
]

const sizes = [
  "0",
  "18rem",
  "32rem",
  "43rem",
  "45rem",
  "56rem",
  "70rem",
  "80rem",
  "120rem",
]

const styles = {
  root: {
    fontFamily: "body",
    fontWeight: "body",
    lineHeight: "body",
    bg: "background",
  },
  a: {
    variant: "links.default",
  },
}

const tokens = {
  text: text.variants || {},
  box: box.variants || {},
}
const theme = {
  useColorSchemeMediaQuery: false,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  links,
  radii,
  styles,
  space,
  sizes,
  ...tokens,
}

export default theme
