export default {
  variants: {
    default: {
      fontSize: [1, 2],
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      color: "grey",
      textAlign: "justify",
    },
    h1: {
      fontSize: [9, null, 10],
      fontWeight: "heading",
      fontFamily: "heading",
      lineHeight: "heading",
    },
    image: {
      subtitle: {
        variant: "text.default",
        fontSize: 6,
        fontWeight: "bold",
      },
    },
    section: {
      title: {
        variant: "text.h1",
        pb: 12,
        textAlign: "center",
      },
    },
  },
}
