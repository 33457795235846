module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/builds/believethehype/danielmazzola.de/src/components/default-page-layout.tsx"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/builds/believethehype/danielmazzola.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Daniel Mazzola","short_name":"Daniel Mazzola","start_url":"/","background_color":"#151818","theme_color":"#c6c6c6","display":"minimal-ui","icon":"src/assets/background_header.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"635631ccb8eeb7f741c7b3c67e4fd2aa"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
