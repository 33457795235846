export default {
  variants: {
    contact: {
      width: "100%",
      maxWidth: 4,
      py: [5, null, 9],
      pl: [4, 7, 12, 13],
      pr: [5, 6],
      boxShadow: "8px 6px 15px 0px rgba(0, 0, 0, 0.08);",
      mb: 2, // don't slice shadow
      minHeight: "1rem",
      backgroundColor: "white",
    },
  },
}
