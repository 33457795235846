export default {
  variants: {
    default: {
      fontSize: [0, null, null, null, 1],
      fontFamily: "body",
      fontWeight: "bold",
      lineHeight: "body",
      color: "grey",
      textDecoration: "underline",
      ":focus": {
        color: "red",
      },
      ":hover": {
        color: "red",
      },
      ":active": {
        color: "red",
      },
    },
  },
}
